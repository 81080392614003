import PropTypes from "prop-types";
import React from "react";
import { Global, css } from "@emotion/core";
import styled from "@emotion/styled";

import SiteMetadata from "./SiteMetadata";

import "sanitize.css";
import "typeface-ubuntu";

const Wrapper = styled.div`
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
`;

const Layout = ({ children }) => (
  <>
    <Global
      styles={css`
        * {
          outline: none;
          user-select: none;
        }

        a {
          color: inherit;
        }

        html,
        body {
          height: 100%;
        }

        html {
          overflow-y: auto;
        }

        body {
          min-height: 100%;
        }
      `}
    />
    <SiteMetadata />
    <Wrapper>{children}</Wrapper>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
