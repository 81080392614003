import { graphql } from "gatsby";
import React from "react";
import styled from "@emotion/styled";

import Layout from "../components/Layout";

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
  text-align: center;

  @media (min-width: 600px) {
    font-size: 4rem;
  }
`;

const Description = styled.p`
  margin: 0;
  text-align: center;

  @media (min-width: 600px) {
    font-size: 1.5rem;
  }
`;

const Overview = styled.div`
  border: 1px solid #000;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  margin: 2rem 0;
  width: 480px;

  video {
    display: block;
    width: 100%;
  }

  @media (min-width: 720px) {
    width: 720px;
  }

  @media (min-width: 1280px) {
    width: 920px;
  }
`;

const Button = styled.a`
  border: 1px solid #fafafa;
  border-radius: 4px;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 0.75rem 2rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s;

  &:hover {
    background-color: #fafafa;
    color: #1a1a1a;
  }
`;

const LinkGroup = styled.div`
  align-items: center;
  display: flex;
  font-size: 0.75rem;
  margin-top: 0.5rem;
`;

const Link = styled.a`
  margin: 0 0.5rem;

  &:hover {
    text-decoration: none;
  }
`;

function IndexPage({ data }) {
  const {
    site: { siteMetadata },
  } = data;

  return (
    <Layout>
      <Wrapper>
        <Title>{siteMetadata.title}</Title>
        <Description>{siteMetadata.description}</Description>
        <Overview>
          <video controls autoplay repeat src={require("../assets/overview.mp4")} />
        </Overview>
        <Button href="https://github.com/Seldszar/focal/releases/latest">Download</Button>
        <LinkGroup>
          <Link href="https://github.com/Seldszar/focal/releases">Previous Releases</Link>
          <Link href="https://github.com/Seldszar/focal">Source Code</Link>
        </LinkGroup>
      </Wrapper>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

export default IndexPage;
