import { graphql, withPrefix, StaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            twitter {
              creator
            }
          }
        }
      }
    `}
    render={({ site: { siteMetadata } }) => {
      const imageUrl = `${siteMetadata.siteUrl}${withPrefix("/icons/icon-512x512.png")}`;

      return (
        <Helmet defaultTitle={siteMetadata.title} titleTemplate={`%s · ${siteMetadata.title}`}>
          <html prefix="og: http://ogp.me/ns#" />

          <meta charSet="utf-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <meta name="description" content={siteMetadata.description} />

          <meta property="og:site_name" content={siteMetadata.title} />
          <meta property="og:url" content={siteMetadata.siteUrl} />
          <meta property="og:title" content={siteMetadata.title} />
          <meta property="og:description" content={siteMetadata.description} />
          <meta property="og:image" content={imageUrl} />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:creator" content={siteMetadata.twitter.creator} />

          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://schema.org",
              "@type": "WebSite",
              url: siteMetadata.siteUrl,
              name: siteMetadata.title,
            })}
          </script>
        </Helmet>
      );
    }}
  />
);
